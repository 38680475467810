const Endpoints = {
  VerifyUser: '/Account/VerifyUser',
  RefeshJWT: '/Account/RefeshJWT',
  SuperAffiliateConfiguration: '/Affiliate/CreateUpdateSuperAffiliateUser',
  GetAffiliateUserconfigurationdetails:
    '/Affiliate/GetAffiliateUserconfigurationdetails',
  EmailSend: '/Affiliate/EmailSend',
  UpdateCountry: '/Utility/UpdateCountryAsync',
  EditCurrency: '/Utility/UpdateCurrencyAsync',
  CreateUpdatePaymentMethod: '/Utility/CreateUpdatePaymentMethodAsync',
  CreateUpdateDeliveryMethod: '/Utility/CreateUpdateDeliveryMethodAsync',
  CreateUpdateEventCategoryAsync: '/Utility/CreateUpdateEventCategoryAsync',
  DeleteEventCategoryAsync: '/Utility/DeleteEventCategoryAsync',
  CreateUpdateEventGenresAsync: '/Utility/CreateUpdateEventGenresAsync',
  DeleteEventGenressAsync: '/Utility/DeleteEventGenressAsync',
  CreateUpdateVenueAsync: '/Utility/CreateUpdateVenueAsync',
  DeleteVenueAsync: '/Utility/DeleteVenueAsync',
  OrderAttention: '/Transaction/TurOnAttentionAsync',
  OrderEditDetails: '/Transaction/OrderDetailsEditAsync',
  ExportOrdersCSV: '/ExportReport/ExportOrdersCSV',
  DownloadTicket: '/Transaction/DownloadTicket',
  PaymentRefund: '/Payment/RefundInitiate',
  TransactionAuditTrail: '/Transaction/AuditTrail',
  ResendTicket: '/Transaction/ResendTicket',
  GetTransactionsummary: '/Transaction/GetTransactionsummary',
  GetAllocations: '/Transaction/GetAllocations',
  ProcessOrder: '/Transaction/ProcessOrder',
  TransactionUpdateStatus: '/Transaction/TransactionUpdateStatus',
  ProcessInvoice: '/Invoice/ProcessInvoice',
  CreateInvoice: '/Invoice/CreateInvoice',
  DownloadInvoice: '/Invoice/DownloadInvoice',
  SendBarcode: '/Transaction/SendBarcode',
  ResendConfirmationEmail: '/Transaction/ResendConfirmationEmail',
  ExportTicketALLOCATION: '/ExportReport/ExportTicketALLOCATION',
  SeatAllocationInBulk: '/Transaction/SeatAllocationInBulk',
  SendBulkBarcodes: '/Transaction/SendBulkBarcodes',
  SendReceiptInBulk: '/Transaction/SendReceiptInBulk',
  ExportWaitListCSV: '/ExportReport/ExportWaitListCSV',
  TimeOutTranscationStatusCheck: '/Transaction/TimeOutTranscationStatusCheck',
  SetPromoterCommissionOfTheEvent: '/Promoter/SetPromoterCommissionOfTheEvent',
  ActiveDeactivePromoterAsync: '/Promoter/ActiveDeactivePromoterAsync',
  ApproveRejectPromoterRequestAsync:
    '/Promoter/ApproveRejectPromoterRequestAsync',
  SendNotification: '/Notification/SendNotification',
  CreateNotificationList: '/Notification/CreateNotificationList',
  FilterList: '/Notification/FilterList',
  DownloadEmailNotificationLog: '/Notification/DownloadEmailNotificationLog',
  ExistingPromotersEntryForNewEvent:
    '/Promoter/ExistingPromotersEntryForNewEvent',
  UpdateInvoiceBankAccount: '/Invoice/UpdateInvoiceBankAccount',
  AddAuditTrail: '/Utility/AddAuditTrail',
  InviteToPromoteTheEventAsync: '/Promoter/InviteToPromoteTheEventAsync',
  ExportUserCSV: '/ExportReport/ExportUserCSV',
  GetUserNotificationFrequencyTypes:
    '/Notification/GetUserNotificationFrequencyTypes',
  SalesEmailNotificationAdminAction:
    '/Notification/SalesEmailNotificationAdminAction',
  UpdateSeat: '/Allocation/UpdateSeat',
  Getseats: '/Allocation/Getseats',
  UploadSeats: '/Allocation/UploadSeats',
  ExportSeatCSV: '/ExportReport/ExportSeatCSV',
  DeleteEventProduct: '/Transaction/DeleteEventProduct',
  SaveEventBasicDetails: '/Event/SaveEventBasicDetails',
  addEventlocation: '/Event/addEventlocation',
  AddEventDate: '/Event/AddEventDate',
  AddEventGeneral: '/Event/AddEventGeneral',
  UniversalSettingForEmailNotification:
    '/Notification/UniversalSettingForEmailNotification',
  UpdateEventSettings: '/Event/UpdateEventSettings',
  AddEventTickets: '/Event/AddEventTickets',
  AddEventSummary: '/Event/AddEventSummary',
  UpdateEventSettingsSuperAffliate: '/Event/UpdateEventSettingsSuperAffliate',
  UnallocateTicket: '/Allocation/UnallocateTicket',
  BulkUpdateSeat: '/Allocation/BulkUpdateSeat',
  ResendAllocationEmail: '/Transaction/ResendAllocationEmail',
  AddEventToTXIS: '/Event/AddEventToTXIS',
  UpdateUserProfile: '/Account/UpdateUserProfileByAdmin',
  ChangePassword: '/Account/ChangePassword',
  AddBank: '/Invoice/AddBank',
  DeleteUserBank: '/Account/DeleteUserBank',
  ActivateUserAccount: '/Account/ActivateUserAccount',
  DeleteUserNotification: '/Account/DeleteUserNotification',
  InitiateEmailVerification: '/Account/InitiateEmailVerification',
  DeleteEmailNotificationList: '/Account/DeleteEmailNotificationList',
  UserRegistration: '/Account/UserRegistrationByAdmin',
  GetFurpBankProviders: '/Bank/GetBankProviders',
  FurpGetBank: '/Bank/GetBanks',
  FurpGetBeneficiaryRequirement: '/Bank/GetBeneficiaryRequirement',
  AddEventToEventBrite: '/EventBrite/AddEventToEventBrite',
  UpdateSiteMaintenance: '/Utility/UpdateSiteMaintenance',
  UpdateEnquiry: '/HelpTicket/UpdateEnquiry',
  AddEnquiry: '/HelpTicket/AddEnquiry',
  ReplyEnquiry: '/HelpTicket/ReplyEnquiry',
  DeteleEventDressTerm: '/Event/DeteleEventDressTerm',
  UserDelete: '/Account/UserDelete',
  RegenerateInvoice: '/Invoice/RegenerateInvoice',
  GetAxsEvents: '/Utility/GetAxsEvents',
  PublishEventExternally: '/Event/PublishEventExternally',
  TransferTickets: '/Transaction/TransferTickets',
  GetTicketToTransfer: '/Transaction/GetTicketToTransfer',
  GetGiganticEvents: '/Event/GetGiganticEvents',
  TicketToRecellCancel: '/Transaction/TicketToRecallReject',
  GetImportedEventDetails: '/Event/GetImportedEventDetails',
  CreateImportedEvent: '/Event/CreateImportedEvent',
  UploadImageToS3: '/Utility/UploadImageToS3',
  ExportRedirectedUsersCSV: '/ExportReport/ExportRedirectedUsersCSV',
  LockTickets: '/Transaction/LockTicketsAsync',
  UserRegistrationForTicket: '/Account/UserRegistration',
  SubmitTransaction: '/Transaction/SubmitTransaction',
  GetAvailableSeats: '/Allocation/GetAvailableSeats',
  ConfirmChangeSeats: '/Allocation/ConfirmChangeSeats',
};

export { Endpoints };