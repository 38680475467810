import * as React from 'react';
import { Heading, Pane, Spinner } from 'evergreen-ui';
import { useReactiveVar } from '@apollo/client';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { isAuthenticatedVar } from '../../helpers/auth';

import apiManager from '../../apiManager';
import { TS_ADMIN_AUTH_TOKEN_REST_API } from '../../constants';
import clientStorage from '../../libs/client-storage';
import useTokenExpiry from '../../hooks/use-token-expiry';
import useLogoutOnTokenExpiry from '../../hooks/use-logout-on-token-expiry';

const Login = React.lazy(() => import('../../pages/login/login'));
const SalesTracker = React.lazy(() =>
  import('../../pages/sales-tracker/single-event/single-event')
);
const MulipleDateEventsSalesTracker = React.lazy(() =>
  import('../../pages/sales-tracker/multiple-events/multiple-events')
);
const SalesEventsProducts = React.lazy(() =>
  import(
    '../../pages/sales-tracker/sales-events-products/sales-events-products'
  )
);
const ProtectedPageLayout = React.lazy(() =>
  import('../protected-page-layout/protected-page-layout')
);

function App() {
  useTokenExpiry();
  // useLogoutOnTokenExpiry();

  React.useEffect(() => {
    const token = clientStorage.getItem(TS_ADMIN_AUTH_TOKEN_REST_API);
    if (token) {
      apiManager.setToken(token);
    }
  }, []);

  return (
    <BrowserRouter>
      <React.Suspense
        fallback={
          <Pane
            width="100vw"
            height="100vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Spinner />
          </Pane>
        }
      >
        <Switch>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>

          <Route exact path="/login">
            <Login />
          </Route>

          {/*  Sales Tracker*/}
          <Route exact path="/sales-tracker/event/:id/:evnetname/:eventdateid">
            <SalesTracker />
          </Route>

          <Route exact path="/sales-tracker/events/:id/:evnetname">
            <MulipleDateEventsSalesTracker />
          </Route>

          <Route
            exact
            path="/sales-tracker/products/:id/:evnetname/:eventdateid"
          >
            <SalesEventsProducts />
          </Route>

          <Route exact path="/admin-web-app">
            {() => {
              const responseData = { status: 'OK' };
              return <>{JSON.stringify(responseData)}</>;
            }}
          </Route>

          <ProtectedRoute component={ProtectedPageLayout} />

          <Route
            exact
            path="*"
            render={() => <Heading>Page not found</Heading>}
          />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
}

function ProtectedRoute({ component: Component, ...rest }) {
  const isAuthenticated = useReactiveVar(isAuthenticatedVar);
  const token = clientStorage.getItem(TS_ADMIN_AUTH_TOKEN_REST_API);

  return (
    <Route {...rest}>
      {isAuthenticated && !!token ? (
        <Component />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { redirectTo: rest.location.pathname },
          }}
        />
      )}
    </Route>
  );
}

export default App;
