import { useEffect, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import get from 'lodash/get';
import { toast } from 'react-toastify';
import {
  authenticatedUserVar,
  parseJwt,
  tokenVar,
  setAuthenticatedUser,
  setIsRestApiAuthenticated,
  setIsAuthenticated,
  isAuthenticatedVar,
} from '../helpers/auth';
import { handleLogout } from '../helpers/auth';
import clientStorage from '../libs/client-storage';
import { TS_ADMIN_AUTH_TOKEN_REST_API } from '../constants';
import { useIdleTimer } from 'react-idle-timer';
import apiManager from '../apiManager';

const IDLE_TIMEOUT = 60 * 60 * 1000;
const TOKEN_REFRESH_THRESHOLD = 60 * 1000;

const isJwtExpired = (expTimestamp) => {
  return Math.floor(Date.now() / 1000) >= expTimestamp;
};

const refeshJWT = async (jwt, jwtrefreshToken) => {
  try {
    const response = await apiManager.refeshJWT({
      jwt: jwt,
      refreshToken: jwtrefreshToken,
    });

    if (response?.data) {
      const Token = response?.data;
      const {
        id: Userid,
        firstName,
        lastName,
        email,
        role,
        superAffiliateid,
        exp,
        refreshToken,
      } = parseJwt(Token);
      const isSuperAffiliate = !!(role === 'SUPERAFFILIATE');
      const id = isSuperAffiliate ? superAffiliateid : Userid;
      setIsAuthenticated(Token);
      setAuthenticatedUser({
        id,
        firstName,
        lastName,
        email,
        role,
        isSuperAffiliate,
        exp,
        refreshToken,
      });
      setIsRestApiAuthenticated(Token);
      apiManager.setToken(Token);

      clientStorage.setItem(
        'count',
        clientStorage.getItem('count')
          ? Number(clientStorage.getItem('count')) + 1
          : 1
      );
    }
  } catch (error) {
    handleLogout();
    toast.info('Your session has expired. Please login again.', {
      position: 'top-center',
      toastId: 'session-expired',
    });
  }
};

const useTokenExpiry = () => {
  const authUser = useReactiveVar(authenticatedUserVar);
  const token = useReactiveVar(tokenVar);
  const isAuth = useReactiveVar(isAuthenticatedVar);
  const refreshTimeoutRef = useRef(null);

  const handleIdle = () => {
    handleLogout();
    if (refreshTimeoutRef.current) clearTimeout(refreshTimeoutRef.current);
    toast.info('Your session has expired. Please login again.', {
      position: 'top-center',
      toastId: 'session-expired',
    });
  };

  useIdleTimer({
    timeout: IDLE_TIMEOUT,
    onIdle: handleIdle,
    debounce: 500,
    disabled: !isAuth,
  });

  useEffect(() => {
    if (!isAuth) {
      if (refreshTimeoutRef.current) clearTimeout(refreshTimeoutRef.current);
      return;
    }

    const exp = get(authUser, 'exp');

    const checkTokenExpiry = () => {
      if (
        clientStorage.getItem(TS_ADMIN_AUTH_TOKEN_REST_API) &&
        isJwtExpired(exp)
      ) {
        if (refreshTimeoutRef.current) clearTimeout(refreshTimeoutRef.current); // Clear any existing timeout
        handleLogout();
        toast.info('Your session has expired. Please login again.', {
          position: 'top-center',
          toastId: 'session-expired',
        });
      }
    };

    checkTokenExpiry();

    const currentTimeSeconds = Math.floor(Date.now() / 1000);
    const timeUntilExpiry = (exp - currentTimeSeconds) * 1000;

    if (timeUntilExpiry > TOKEN_REFRESH_THRESHOLD) {
      if (refreshTimeoutRef.current) clearTimeout(refreshTimeoutRef.current);
      refreshTimeoutRef.current = setTimeout(
        () =>
          refeshJWT(
            clientStorage.getItem(TS_ADMIN_AUTH_TOKEN_REST_API),
            authUser.refreshToken
          ),
        timeUntilExpiry - TOKEN_REFRESH_THRESHOLD
      );

      return () => {
        if (refreshTimeoutRef.current) clearTimeout(refreshTimeoutRef.current);
      };
    } else {
      checkTokenExpiry();
    }
  }, [authUser, token, isAuth]);

  return { token, authUser };
};

export default useTokenExpiry;
